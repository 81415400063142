import { useState } from 'react'
import '../styles/ContactForm.css'
import React from 'react'
import { BsTelegram, BsWhatsapp, BsInstagram, BsLinkedin } from 'react-icons/bs'

const ContactForm = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        subject: '',
        message: '',
    })

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log('Form submitted:', formData)
    }

    return (
        <div className="containerpaginaContact">
            <div className="contact-container">
                <div className="Pcontact-header">
                    <h3 className="contact-header">Contact Us!</h3>
                </div>
                <form className="barreForm" onSubmit={handleSubmit}>
                    <div className="input-group">
                        <input
                            className="barraName"
                            type="text"
                            name="firstName"
                            placeholder="Name"
                            value={formData.firstName}
                            onChange={handleChange}
                            required
                        />
                        <input
                            className="barraNumber"
                            type="text"
                            name="lastName"
                            placeholder="Number"
                            value={formData.lastName}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="ContainerEmailSubject">
                        <div className="boxBarraEmail">
                            <input
                                className="barraEmail"
                                type="email"
                                name="email"
                                placeholder="E-mail"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="boxBarraSubject">
                            <input
                                className="barraSubject"
                                type="text"
                                name="subject"
                                placeholder="Subject"
                                value={formData.subject}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="PTextareaContainer">
                        <textarea
                            className="TextareaContainer"
                            name="message"
                            placeholder="Comments / Questions"
                            value={formData.message}
                            onChange={handleChange}
                            rows="4"
                            required
                        />
                    </div>
                    <div className="PcontainerParagrafoForm">
                        <p className="containerParagrafoForm">
                            Send us a message with your question: I will reply
                            to all of them within 24 hours at the latest.
                        </p>
                    </div>
                    <div className="ContainerPbtnContact">
                        <button className="btnContact" type="submit">
                            Join us today
                        </button>
                    </div>
                </form>
            </div>
            <div className="Container-social-botton">
                <div className="whatsapp-button-container">
                    <a
                        href="https://api.whatsapp.com/message/D6LL2W3L7WMJL1?autoload=1&app_absent=0"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="whatsapp-button"
                    >
                        <BsWhatsapp className="whatsapp-icon" />
                    </a>
                </div>
                <a
                    href="https://www.instagram.com/lvdere?igsh=NHFwdnFjdmVmNHNt"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="Instangram-buttom"
                >
                    <BsInstagram className="Instangram-icon" />
                </a>
                <a
                    href="https://www.linkedin.com/company/lvdere"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="Linkedin-buttom"
                >
                    <BsLinkedin className="Linkedin-icon" />
                </a>
                <a
                    href="https://www.linkedin.com/company/lvdere"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="Telegram-buttom"
                >
                    <BsTelegram className="Telegram-icon" />
                </a>
            </div>
        </div>
    )
}

export default ContactForm

// import { useState } from 'react'
// import '../styles/ContactForm.css'
// import React from "react";
// import { FaTelegram, FaWhatsapp, FaInstagram, FaLinkedin,  } from 'react-icons/fa';

// const ContactForm = () => {
//     const [formData, setFormData] = useState({
//         firstName: '',
//         lastName: '',
//         email: '',
//         subject: '',
//         message: '',
//     })

//     const handleChange = (e) => {
//         setFormData({ ...formData, [e.target.name]: e.target.value })
//     }

//     const handleSubmit = (e) => {
//         e.preventDefault()
//         console.log('Form submitted:', formData)
//     }

//     return (
//         <div className="containerpaginaContact">
//             <div className="contact-container">
//                 <div className="Pcontact-header">
//                     <h3 className="contact-header">Contacts</h3>
//                 </div>
//                 <form className="barreForm" onSubmit={handleSubmit}>
//                     <div className="input-group">
//                         <input
//                             className="barraName"
//                             type="text"
//                             name="firstName"
//                             placeholder="Name"
//                             value={formData.firstName}
//                             onChange={handleChange}
//                             required
//                         />
//                         <input
//                             className="barraNumber"
//                             type="text"
//                             name="lastName"
//                             placeholder="Number"
//                             value={formData.lastName}
//                             onChange={handleChange}
//                             required
//                         />
//                     </div>
//                     <div className="ContainerEmailSubject">
//                         <div>
//                             <input
//                                 className="barraEmail"
//                                 type="email"
//                                 name="email"
//                                 placeholder="E-mail"
//                                 value={formData.email}
//                                 onChange={handleChange}
//                                 required
//                             />
//                         </div>
//                         <div className="boxBarraSubject">
//                             <input
//                                 className="barraSubject"
//                                 type="text"
//                                 name="subject"
//                                 placeholder="Subject"
//                                 value={formData.subject}
//                                 onChange={handleChange}
//                                 required
//                             />
//                         </div>
//                     </div>
//                     <div className="PTextareaContainer">
//                         <textarea
//                             className="TextareaContainer"
//                             name="message"
//                             placeholder="Comments / Questions"
//                             value={formData.message}
//                             onChange={handleChange}
//                             rows="4"
//                             required
//                         />
//                     </div>
//                     <div className="PcontainerParagrafoForm">
//                         <p className="containerParagrafoForm">

//                             Send us a message with your question: I will reply to all of them within 24 hours at the latest.

//                         </p>
//                     </div>
//                     <div className="containerParagrafoFormDiv">
//                         <div className="PbtnContact">
//                             <button className="btnContact" type="submit">
//                                 Join us today
//                             </button>
//                     </div>
//                         </div>
//                 </form>
//             </div>
//             <div className="Container-social-botton">

//                        <div className="whatsapp-button-container">
//                             <a
//                                 href="https://api.whatsapp.com/message/D6LL2W3L7WMJL1?autoload=1&app_absent=0"
//                                 target="_blank"
//                                 rel="noopener noreferrer"
//                                 className="whatsapp-button"
//                             >
//                                 <FaWhatsapp className="whatsapp-icon" />
//                             </a>
//                         </div>
//                         <div className="Telegram-buttom-container">
//                             <a
//                                 href=""
//                                 target="_blank"
//                                 rel="noopener noreferrer"
//                                 className="Telegram-buttom"
//                             >
//                                 <FaTelegram className="Telegram-icon" />
//                             </a>

//                         </div>
//                         <div className="Instangram-buttom-container">
//                             <a
//                                 href=""
//                                 target="_blank"
//                                 rel="noopener noreferrer"
//                                 className="Instangram-buttom"
//                             >
//                                 <FaInstagram className="Telegram-icon" />
//                             </a>

//                         </div>
//                         <div className="Linkedin-buttom-container">
//                             <a
//                                 href=""
//                                 target="_blank"
//                                 rel="noopener noreferrer"
//                                 className="Linkedin-buttom"
//                             >
//                                 <FaLinkedin className="Telegram-icon" />
//                             </a>

//                         </div>
//                        </div>
//         </div>
//     )
// }

// export default ContactForm
